@import 'src/styles/shared';

.promotionRoot {
  position: relative;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid silver;
  .numInput {
    align-content: center;
    flex: 1 1 calc(50% - 8px);
    min-width: 150px;
    padding-top: 8px;
  }
  .promotionActions {
    display: flex;
    justify-content: flex-end;
    .MuiButton-root {
      margin: 0 8px 8px 0;
    }
  }
  ._formRow, ._formRowDouble {
    margin-bottom: 0;
  }
  ._directionCol.productColumn, ._directionCol.pricesColumn {
    display: flex;
    width: 30%;
    padding: 0 6px;
    ._formRowDouble {
      flex-wrap: wrap;
      gap: 12px;
      &>div {
        padding-right: 0;
      }
      &>div:last-child {
        margin-left: 0;
      }
    }
  }
  ._directionCol.productColumn {
    padding-top: 12px;
  }
  ._directionCol.typesColumn {
    width: 40%;
    padding: 0 8px;
  }
  .productPropertyRow {
    display: flex;
    padding-bottom: 4px;
    font-size: 0.9em;
  }
  .productPropertyCol {
    font-family: $font-family-bold;
    display: flex;
    align-items: center;
    width: 100%;
    overflow-wrap: anywhere;
  }
  .productPropertyLabel {
    font-family: $font-family-regular;
    padding-right: 4px;
  }
  .unidentifiedRow {
    color: $_light;
  }
  .isTemplate {
    color: $mpm-red;
    font-family: $font-family-bold;
  }
  .promoInvalidMsg {
    font-size: 0.75rem;
    color: $mpm-red;
  }
  .promotionValidityRoot {
    padding-bottom: 6px;
  }
  .priceProposal {
    background-color: lighten($mpm-red-light, 10%);
  }
}
.promotionRoot:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.derivedPropertyContainer {
  display: flex;
  margin-top: 8px;
  &>div:first-child {
    padding-right: 8px;
    width: 85%;
  }
  &>div:last-child {
    margin: auto;
  }
  .MuiButtonBase-root:last-child {
    margin-left: 2.5%;
  }
}
.giveAwayBundlingRow {
  padding: 12px 0 0 12px;
  .giveAwayBundlingTooltip {
    display: inline;
    .giveAwayBundlingItem {
      margin-right: 5px;
    }
  }
}